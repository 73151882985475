module WinJS.UI {

    export class TreeView {


        private _element: HTMLElement
        public get element(): HTMLElement {
            return this._element;
        }


        private _itemDataSource: WinJS.UI.IListDataSource<any>
        public get itemDataSource(): WinJS.UI.IListDataSource<any> {
            return this._itemDataSource;
        }
        public set itemDataSource(value: WinJS.UI.IListDataSource<any>) {
            //if (this._data) 
            //    this._removeDataListeners();

            this._itemDataSource = value || new WinJS.Binding.List().dataSource;
            this.attachItemsHandler();
            this.updateView();
        }


        private _itemTemplate: WinJS.Binding.Template
        public get itemTemplate(): WinJS.Binding.Template {
            return this._itemTemplate;
        }

        private _placeholderTemplate: WinJS.Binding.Template
        public get placeholderTemplate(): WinJS.Binding.Template {
            return this._placeholderTemplate;
        }



        private _id: string
        public get id(): string {
            return this._id;
        }

        private _pid: string
        public get pid(): string {
            return this._pid;
        }



        private _root: HTMLUListElement;


        private _reflowing: boolean;
        private _reflowQueue: Array<any>;

        private _listBinding: IListBinding<any>;

        private _listMap: Array<any>;
        private _listHandles: IItemPromise<IItem<any>>[];

        private _highlightEnabled: boolean = true;


        private _disposed: boolean;





        private _onItemSelected: EventListenerObject
        public get onItemSelected(): EventListenerObject {
            return this._onItemSelected;
        }
        public set onItemSelected(eventHandler: EventListenerObject) {
            if (this._onItemSelected) {
                this.removeEventListener("itemSelected", this._onItemSelected);
                this._onItemSelected = null;
            }

            this._onItemSelected = eventHandler;
            addEventListener("itemSelected", this._onItemSelected);
        }


        private _onItemInvoked: EventListenerObject
        public get onItemInvoked(): EventListenerObject {
            return this._onItemInvoked;
        }
        public set onItemInvoked(eventHandler: EventListenerObject) {
            if (this._onItemInvoked) {
                removeEventListener("itemInvoked", this._onItemInvoked);
                this._onItemInvoked = null;
            }

            this._onItemInvoked = eventHandler;
            addEventListener("itemInvoked", this._onItemInvoked);
        }

        private _onItemToggled: EventListenerObject
        public get onItemToggled(): EventListenerObject {
            return this._onItemToggled;
        }
        public set onItemToggled(eventHandler: EventListenerObject) {
            if (this._onItemToggled) {
                removeEventListener("itemToggled", this._onItemToggled);
                this._onItemToggled = null;
            }

            this._onItemToggled = eventHandler;
            addEventListener("itemToggled", this._onItemToggled);
        }

        private _onItemContextInvoked: EventListenerObject
        public get onItemContextInvoked(): EventListenerObject {
            return this._onItemContextInvoked;
        }
        public set onItemContextInvoked(eventHandler: EventListenerObject) {
            if (this._onItemContextInvoked) {
                removeEventListener("itemContext", this._onItemContextInvoked);
                this._onItemContextInvoked = null;
            }

            this._onItemContextInvoked = eventHandler;
            addEventListener("itemContext", this._onItemContextInvoked);
        }




        private _asyncWork: IPromise<any>[] = [];








        constructor(element: HTMLElement, options: any) {
            element = element || document.createElement("div");
            options = options || {};

            this._disposed = false;

            if (!options.idMember)
                throw Error("Treeview requires an id member to be specified");
            this._id = options.idMember;

            if (!options.pidMember)
                throw Error("Treeview requires a parent id member to be specified");
            this._pid = options.pidMember;


            element.winControl = this;
            element.classList.add('win-disposable');


            this._element = element;

            if (!options.itemDataSource) {
                var list = new WinJS.Binding.List();
                this._itemDataSource = list.dataSource;
            }
            else {
                this._itemDataSource = options.itemDataSource;
            }


            if (!options.placeholderTemplate)
                throw Error("Treeview requires a place holder template to be specified");
            this._placeholderTemplate = options.placeholderTemplate.winControl;


            if (!options.itemTemplate)
                throw Error("Treeview requires an item template to be specified");
            this._itemTemplate = options.itemTemplate.winControl;


            if (options.highlightEnabled === false)
                this._highlightEnabled = options.highlightEnabled;

            this._reflowing = false;
            this._reflowQueue = [];

            this._listHandles = [];
            this._listMap = [];

            this.attachEvents();
            this.attachItemsHandler();

            this.updateView();

            //WinJS.UI.setOptions(this, options);
            //WinJS.UI.setControl(this._element, this); 
        }



        private dispatchEvent = (name: string, id: string, itemPromise: IItemPromise<any>) => {
            var event;
            try {
                event = new CustomEvent(name, {
                    bubbles: true,
                    cancelable: true,
                    detail: {
                        id: id,
                        itemPromise: itemPromise
                    }
                });
            } catch (e) {
                event = document.createEvent('CustomEvent');
                event.initCustomEvent(name, true, true, { id: id, itemPromise: itemPromise });
            }

            this.element.dispatchEvent(event);
        }



        private detachEvents = () => {
            //TODO
        }

        private attachEvents = () => {

            var modeHandler = (eventName: string, caseSensitive?: boolean, capture?: boolean) => {
                return {
                    capture: capture,
                    name: (caseSensitive ? eventName : eventName.toLowerCase()),
                    handler: (eventObject) => {
                        var name = "on" + eventName;
                        if (!this._disposed && this[name]) {
                            this[name](eventObject);
                        }
                    }
                };
            }

            var events = [
                //modeHandler("PointerDown"),
                modeHandler("click", false),
                //modeHandler("PointerUp"),
                //modeHandler("LostPointerCapture"),
                //modeHandler("MSHoldVisual", true),
                //modeHandler("PointerCancel", true),
                //modeHandler("DragStart"),
                //modeHandler("DragOver"),
                //modeHandler("DragEnter"),
                //modeHandler("DragLeave"),
                //modeHandler("Drop"),
                modeHandler("contextmenu")
            ];
            events.forEach((eventHandler) => {
                this._element.addEventListener(eventHandler.name, eventHandler.handler, !!eventHandler.capture);
            });
        }



        private detachItemsHandler = () => {
            // TODO
        }

        private attachItemsHandler = () => {

            var notificationHandler: IListNotificationHandler<any> = {

                beginNotifications: function TreeView_beginNotifications() {
                },

                endNotifications: function TreeView_endNotifications() {
                },


                changed: function ListView_changed(newItem: IItem<any>, oldItem: IItem<any>) {
                    if (this.isDisposed) { return; }

                    //that._createUpdater();

                    //var elementInfo = that._updater.elements[uniqueID(oldItem)];
                    //if (elementInfo) {
                    //    var selected = that.selection._isIncluded(elementInfo.index);
                    //    if (selected) {
                    //        that._updater.updateDrag = true;
                    //    }

                    //    if (oldItem !== newItem) {
                    //        if (that._tabManager.childFocus === oldItem || that._updater.newFocusedItem === oldItem) {
                    //            that._updater.newFocusedItem = newItem;
                    //            that._tabManager.childFocus = null;
                    //        }

                    //        if (elementInfo.itemBox) {
                    //            _ElementUtilities.addClass(newItem, _Constants._itemClass);
                    //            that._setupAriaSelectionObserver(newItem);

                    //            var next = oldItem.nextElementSibling;
                    //            elementInfo.itemBox.removeChild(oldItem);
                    //            elementInfo.itemBox.insertBefore(newItem, next);
                    //        }

                    //        that._setAriaSelected(newItem, selected);
                    //        that._view.items.setItemAt(elementInfo.newIndex, {
                    //            element: newItem,
                    //            itemBox: elementInfo.itemBox,
                    //            container: elementInfo.container,
                    //            itemsManagerRecord: elementInfo.itemsManagerRecord
                    //        });
                    //        delete that._updater.elements[uniqueID(oldItem)];
                    //        _Dispose._disposeElement(oldItem);
                    //        that._updater.elements[uniqueID(newItem)] = {
                    //            item: newItem,
                    //            container: elementInfo.container,
                    //            itemBox: elementInfo.itemBox,
                    //            index: elementInfo.index,
                    //            newIndex: elementInfo.newIndex,
                    //            itemsManagerRecord: elementInfo.itemsManagerRecord
                    //        };
                    //    } else if (elementInfo.itemBox && elementInfo.container) {
                    //        _ItemEventsHandler._ItemEventsHandler.renderSelection(elementInfo.itemBox, newItem, selected, true);
                    //        _ElementUtilities[selected ? "addClass" : "removeClass"](elementInfo.container, _Constants._selectedClass);
                    //    }
                    //    that._updater.changed = true;
                    //}
                    //for (var i = 0, len = that._notificationHandlers.length; i < len; i++) {
                    //    that._notificationHandlers[i].changed(newItem, oldItem);
                    //}
                    //that._writeProfilerMark("changed,info");
                },

                countChanged: function TreeView_countChanged(newCount: number, oldCount: number) {

                },


                //updateAffectedRange: function TreeView_updateAffectedRange(newerRange) {
                //    //that._itemsCount().then(function (count) {
                //    //    // When we receive insertion notifications before all of the containers have
                //    //    // been created and the affected range is beyond the container range, the
                //    //    // affected range indices will not correspond to the indices of the containers
                //    //    // created by updateContainers. In this case, start the affected range at the end
                //    //    // of the containers so that the affected range includes any containers that get
                //    //    // appended due to this batch of notifications.
                //    //    var containerCount = that._view.containers ? that._view.containers.length : 0;
                //    //    newerRange.start = Math.min(newerRange.start, containerCount);

                //    //    that._affectedRange.add(newerRange, count);
                //    //});
                //    //that._createUpdater();
                //    //that._updater.changed = true;
                //},

                indexChanged: function TreeView_indexChanged(handle: string, newIndex: number, oldIndex: number) {
                    // We should receive at most one indexChanged notification per oldIndex
                    // per notification cycle.
                    if (this.isDisposed) { return; }

                    //that._createUpdater();

                    //if (item) {
                    //    var itemObject = that._itemsManager.itemObject(item);
                    //    if (itemObject) {
                    //        that._groupFocusCache.updateItemIndex(itemObject.key, newIndex);
                    //    }

                    //    var elementInfo = that._updater.elements[uniqueID(item)];
                    //    if (elementInfo) {
                    //        elementInfo.newIndex = newIndex;
                    //        that._updater.changed = true;
                    //    }
                    //    that._updater.itemsMoved = true;
                    //}
                    //if (that._currentMode()._dragging && that._currentMode()._draggingUnselectedItem && that._currentMode()._dragInfo._isIncluded(oldIndex)) {
                    //    that._updater.newDragInfo = new _SelectionManager._Selection(that, [{ firstIndex: newIndex, lastIndex: newIndex }]);
                    //    that._updater.updateDrag = true;
                    //}

                    //if (that._updater.oldFocus.type !== _UI.ObjectType.groupHeader && that._updater.oldFocus.index === oldIndex) {
                    //    that._updater.newFocus.index = newIndex;
                    //    that._updater.changed = true;
                    //}

                    //if (that._updater.oldSelectionPivot === oldIndex) {
                    //    that._updater.newSelectionPivot = newIndex;
                    //    that._updater.changed = true;
                    //}

                    //var range = that._updater.selectionFirst[oldIndex];
                    //if (range) {
                    //    range.newFirstIndex = newIndex;
                    //    that._updater.changed = true;
                    //    that._updater.selectionChanged = true;
                    //    that._updater.updateDrag = true;
                    //}
                    //range = that._updater.selectionLast[oldIndex];
                    //if (range) {
                    //    range.newLastIndex = newIndex;
                    //    that._updater.changed = true;
                    //    that._updater.selectionChanged = true;
                    //    that._updater.updateDrag = true;
                    //}
                },

                inserted: (itemPromise: IItemPromise<any>, previousHandle: string, nextHandle: string) => {
                    if (this._disposed)
                        return;

                    if (this._listHandles[itemPromise.handle] != null)
                        return;

                    itemPromise.done((item: IItem<any>) => {

                        this._itemTemplate.render(item.data).done((element) => {

                            element.classList.add('win-node-object');
                            var id = this.addNode(item.data[this._id], item.data[this._pid], element);

                            this._listHandles[itemPromise.handle] = itemPromise;
                            this._listMap[id] = itemPromise.handle

                            console.log("treeview-winjs: node added");
                        });
                    });
                    itemPromise.retain();

                    if (this._itemDataSource.list.length > 0) {
                        var placeholder = this._root.querySelector(".win-placeholder");

                        if (placeholder)
                            placeholder.parentNode.removeChild(placeholder);
                    }


                },

                removed: (handle: string, mirage: boolean) => {
                    if (this._disposed) { return; }


                    var itemPromise = <IItemPromise<IItem<any>>>this._listHandles[handle];
                    itemPromise.done((item: IItem<any>) => {
                        this.removeNode(item.data[this._id]);

                        // TODO removing a parent node will orphan its children and create a memory leak
                        // should remove from listmap too 
                        //this._listHandles[itemPromise.handle] = itemPromise;
                        //this._listMap[id] = itemPromise.handle

                        console.log("treeview-winjs: node removed");
                    })
                    itemPromise.release();


                    if (this._itemDataSource.list.length == 0) {
                        var placeholder = this._root.querySelector(".win-placeholder");

                        if (!placeholder)
                            this._placeholderTemplate.render({})
                                .then((itemElement) => {
                                    itemElement.classList.add("win-placeholder");
                                    this._root.appendChild(itemElement);
                                });
                    }

                },


                moved: function TreeView_moved(item: IItemPromise<any>, previousHandle: string, nextHandle: string) {
                    if (this.isDisposed) { return; }

                    //that._createUpdater();

                    //that._updater.movesCount++;
                    //if (item) {
                    //    that._updater.itemsMoved = true;

                    //    var elementInfo = that._updater.elements[uniqueID(item)];
                    //    if (elementInfo) {
                    //        elementInfo.moved = true;
                    //    }
                    //}

                    //var index = that._updater.selectionHandles[itemPromise.handle];
                    //if (index === +index) {
                    //    that._updater.updateDrag = true;
                    //    that._updater.selectionChanged = true;

                    //    var firstRange = that._updater.selectionFirst[index],
                    //        lastRange = that._updater.selectionLast[index],
                    //        range = firstRange || lastRange;

                    //    if (range && range.oldFirstIndex !== range.oldLastIndex) {
                    //        delete that._updater.selectionFirst[range.oldFirstIndex];
                    //        delete that._updater.selectionLast[range.oldLastIndex];
                    //        that._updater.changed = true;
                    //    }
                    //}
                    //that._writeProfilerMark("moved(" + index + "),info");
                },

                //reload: function ListView_reload() {
                //    if (this.isDisposed) { return; }

                //    //that._processReload();
                //},


                itemAvailable: function (item: IItem<any>) {
                }

            };


            if (this._itemDataSource) {
                Object.keys(this._listHandles).forEach((key) => {
                    this._listHandles[key].release()
                });
            }

            this._listBinding = this._itemDataSource.createListBinding(notificationHandler);


            function statusChanged(eventObject) {
            }

            if (this._itemDataSource.list.addEventListener) {
                this._itemDataSource.list.addEventListener("statuschanged", statusChanged, false);
            }
        }



        private updateView = () => {

            WinJS.Promise.join(this._asyncWork)
                .then(() => {
                    console.log("draw cleared");
                    this._asyncWork = []
                })
                .then(() => {

                    console.log("draw started");

                    WinJS.Utilities.empty(this._element);

                    var root = document.createElement('ul');
                    root.classList.add('win-root', 'win-node-container');

                    this._element.appendChild(root);
                    this._root = root;

                    var itemsCount = this._itemDataSource.list.length;
                    if (itemsCount > 0) {

                        for (var i = 0; i < itemsCount; i++) {
                            this._asyncWork.push(
                                ((itemPromise: IItemPromise<any>): IPromise<any> => {

                                    return itemPromise.retain()
                                        .then((item: IItem<any>) => {
                                            var itemElement = this._itemTemplate.render(item.data);
                                            return WinJS.Promise.join({ itemElement, item });
                                        })
                                        .then((result: { itemElement: HTMLElement, item: IItem<any> }) => {
                                            result.itemElement.classList.add('win-node-object');
                                            var id = this.addNode(result.item.data[this._id], result.item.data[this._pid], result.itemElement);

                                            this._listHandles[itemPromise.handle] = itemPromise;
                                            this._listMap[id] = itemPromise.handle;

                                            console.log("treeview-winjs: node added");
                                        });

                                })(this._listBinding.fromIndex(i)));
                        }

                        console.log("draw added " + this._asyncWork.length);

                    }
                    else {

                        this._placeholderTemplate.render({})
                            .then((itemElement) => {
                                itemElement.classList.add("win-placeholder");
                                root.appendChild(itemElement);
                            });

                        console.log("draw empty ");

                    }

                });
        }




        private getNodeContainer(id: string): HTMLElement {
            var container;

            if (id) {
                var pnode = this.getNode(id);
                if (!(container = WinJS.Utilities.query('ul', pnode)[0])) {
                    var cnode = document.createElement('ul');
                    cnode.classList.add('win-node-container');

                    container = pnode.appendChild(cnode);
                }
            } else {
                container = this._root;
            }

            return container;
        }

        public getNode(id: string): HTMLElement {
            var node;
            if (id) {
                node = WinJS.Utilities.query("[data-win-treeview-id='" + id + "']", this._element).get(0);

            } else {
                node = this._root;
            }

            return node;
        }



        private measure(element: HTMLElement): number {
            var clone = <HTMLElement>element.cloneNode(true);
            clone.style.visibility = "hidden";
            clone.style.maxHeight = "none";
            element.parentElement.appendChild(clone);
            var height = clone.clientHeight;
            element.parentElement.removeChild(clone);

            return height;
        }

        private queueReflow(id: string, collapsing?: boolean) {

            var reflow = (id: string, collapsing?: boolean) => {

                var nodeContainer = this.getNodeContainer(id);

                if (!collapsing)
                    var height = this.measure(nodeContainer);
                else
                    var height = nodeContainer.clientHeight;

                return WinJS.UI.executeTransition(nodeContainer, {
                    property: "max-height",
                    delay: 0,
                    duration: 200,
                    timing: "cubic-bezier(0.1, 0.9, 0.2, 1)",
                    from: !collapsing ? "0px" : height + "px",
                    to: !collapsing ? height + "px" : "0px"
                }).then(() => {
                    if (!collapsing)
                        nodeContainer.style.maxHeight = "none";
                }).done(() => {
                    this._reflowing = this._reflowQueue.length > 0

                    if (this._reflowQueue.length > 0) {
                        var queue = this._reflowQueue.shift();
                        reflow(queue.id, queue.collapsing);
                    }
                });
            }

            if (this._reflowing)
                this._reflowQueue.push({ id: id, collapsing: collapsing });
            else {
                this._reflowing = true;
                reflow(id, collapsing);
            }
        }



        private highlight(id: string) {
            var node = this.getNode(id);

            if (!node.classList.contains('node-highlighted')) {
                WinJS.Utilities.query("li.win-node", this._element).forEach((node) => {
                    node.classList.remove('node-highlighted');
                    node.style.cssText = "";
                });

                if (!this._highlightEnabled)
                    return;

                var offset = WinJS.Utilities.getPosition(node).left - WinJS.Utilities.getPosition(this._root).left;
                node.style.marginLeft = -offset + "px";
                node.style.paddingLeft = offset + "px";


                node.classList.add('node-highlighted');
            }
        }

        public select(id) {
            var node = this.getNode(id);
            this.highlight(id);

            if (!node.classList.contains('node-selected')) {
                WinJS.Utilities.query('li.win-node', this._element).forEach((node) => {
                    node.classList.remove('node-selected');
                });

                node.classList.add('node-selected');
                this.dispatchEvent('itemSelected', id, this._listHandles[this._listMap[node.id]]);
            }
        }





        private onclick = (event: MouseEvent) => {
            event.stopPropagation();
            event.preventDefault();

            var element = <HTMLElement>event.target;
            if (WinJS.Utilities.hasClass(element, 'win-node-toggle')) {
                var id = element.parentElement.getAttribute('data-win-treeview-id');
                this.toggle(id);

                //var state = this.getNode(id).classList.contains('closed') ? 'open' : 'close';

                //this.dispatchEvent('itemToggled', id, this._listHandles[this._listMap[element.id]]);
            }
            else if (WinJS.Utilities.hasClass(element, 'win-node')) {
                var id = element.getAttribute('data-win-treeview-id');
                this.select(id);
                this.dispatchEvent('itemInvoked', id, this._listHandles[this._listMap[element.id]]);
            }
            else {
                while ((element = element.parentElement) && !element.classList.contains('win-node'));
                if (element) {
                    var id = element.getAttribute('data-win-treeview-id');
                    this.select(id);
                    this.dispatchEvent('itemInvoked', id, this._listHandles[this._listMap[element.id]]);
                }
            }
        }

        private oncontextmenu = (event: MouseEvent) => {
            event.stopPropagation();
            event.preventDefault();

            var element = <HTMLElement>event.target;
            if (WinJS.Utilities.hasClass(element, 'win-node')) {
                var id = element.getAttribute('data-win-treeview-id');
                this.select(id);
                this.dispatchEvent('itemContext', id, this._listHandles[this._listMap[element.id]]);

            }
            else {
                while ((element = element.parentElement) && !element.classList.contains('win-node'));
                if (element) {
                    var id = element.getAttribute('data-win-treeview-id');
                    this.select(id);
                    this.dispatchEvent('itemContext', id, this._listHandles[this._listMap[element.id]]);
                }
            }
        }




        private addNode(id: any, pid: any, element: HTMLElement) {

            if (!id)
                throw new Error("All treeview data elements must have an id");

            var nodecontainer = this.getNodeContainer(pid);

            var node = document.createElement('li');
            node.classList.add('win-node');
            node.setAttribute('data-win-treeview-id', id);


            var span = document.createElement('span');
            span.classList.add('win-node-toggle');

            node.appendChild(span);
            node.appendChild(element);

            nodecontainer.appendChild(node);


            if (nodecontainer !== this._root)
                nodecontainer.parentElement.classList.add('win-has-children');

            var uniqueID = WinJS.Utilities._uniqueID(node);
            node.id = uniqueID;
            return uniqueID;

            //this.dispatch('add', id);
        }

        private removeNode(id: string) {
            var node = this.getNode(id);

            if (node) {
                var nodecontainer = node.parentElement;
                nodecontainer.removeChild(node);
                nodecontainer.parentElement.classList.toggle('node-has-children', !!nodecontainer.children.length);
            }

            //this.dispatch('remove', id);
        }







        public open(id: string) {
            var node = this.getNode(id);
            this.queueReflow(id);

            node.classList.remove('closed');
        }

        public close(id: string) {
            var node = this.getNode(id);
            this.queueReflow(id, true);

            node.classList.add('closed');
        }

        public toggle(id: string) {
            // call either the open or close function depending on what we have toggled
            this[this.getNode(id).classList.contains('closed') ? 'open' : 'close'](id);
        }





        public addEventListener = function (type: string, listener: EventListenerOrEventListenerObject, useCapture?: boolean) {
            this.element.addEventListener(type, listener, useCapture);
        };

        public removeEventListener = function (type: string, listener: EventListenerOrEventListenerObject, useCapture?: boolean) {
            this.element.removeEventListener(type, listener, useCapture);
        };



        public dispose = () => {
            if (!this._disposed) {
                this._disposed = true;

                WinJS.Utilities.disposeSubTree(this.element);

                this.onItemSelected = null;
                this.onItemContextInvoked = null;
                this.onItemInvoked = null;
            }
        }

    }


    //WinJS.Class.mix(WinJS.UI.TreeView, WinJS.Utilities.createEventProperties("opened", "closed"), WinJS.UI.DOMEventMixin);
    WinJS.Utilities.markSupportedForProcessing(WinJS.UI.TreeView);


    WinJS.Namespace.define("WinJS.UI", {
        TreeView: WinJS.UI.TreeView
    });
}


